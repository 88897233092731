import { createStyles, keyframes } from '@mantine/emotion';
import React from 'react';

const moveForever1 = keyframes({
  '0%': { transform: 'translate(85px, 0%)' },
  '100%': { transform: 'translate(-90px, 0%)' },
});

const moveForever2 = keyframes({
  '0%': { transform: 'translate(-95px, 0%)' },
  '100%': { transform: 'translate(80px, 0%)' },
});

const moveForever3 = keyframes({
  '0%': { transform: 'translate(85px, 0%)' },
  '100%': { transform: 'translate(-90px, 0%)' },
});

const moveForever4 = keyframes({
  '0%': { transform: 'translate(85px, 0%)' },
  '100%': { transform: 'translate(-90px, 0%)' },
});

const useStyles = createStyles((theme) => ({
  content: {
    width: '100%',
    height: '100%',
    '> #section:nth-of-type(odd)': {
      backgroundColor: 'red',
    },
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.colors.gray[0],
  },
  section: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    '&:last-child': {
      background: 'unset',
      backgroundColor: theme.colors.gray[0],
    },
  },

  editorial: {
    display: 'block',
    width: '100%',
    height: '60px',
    maxHeight: '60px',
    margin: 0,
    zIndex: 5,
    bottom: 0,
    position: 'absolute',
    left: '0px',
    float: 'left',
  },

  parallax1: {
    '& > use': {
      animation: `${moveForever1} 9s linear infinite`,
      '&:nth-of-type(1)': {
        animationDelay: '-1s',
      },
    },
  },

  parallax2: {
    '& > use': {
      animation: `${moveForever2} 8s linear infinite`,
      '&:nth-of-type(1)': {
        animationDelay: '-2s',
      },
    },
  },

  parallax3: {
    '& > use': {
      animation: `${moveForever3} 6s linear infinite`,
      '&:nth-of-type(1)': {
        animationDelay: '-2s',
      },
    },
  },

  parallax4: {
    '& > use': {
      animation: `${moveForever4} 11s linear infinite`,
      '&:nth-of-type(1)': {
        animationDelay: '-2s',
      },
    },
  },
}));

interface WaveProps {
  colors: string[];
  order: number[];
  height: number | string;
}

export const Waves: React.FC<WaveProps> = ({ colors, order, height }) => {
  const { classes } = useStyles();

  return (
    <svg
      width={'100%'}
      height={height}
      className="editorial"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 24 150 28 "
      preserveAspectRatio="none">
      <defs>
        <path
          id="gentle-wave"
          d="M-160 44c30 0 
    58-18 88-18s
    58 18 88 18 
    58-18 88-18 
    58 18 88 18
    v44h-352z"
        />
      </defs>
      <g className={classes[`parallax${order[0]}`]}>
        <use xlinkHref={'#gentle-wave'} x="50" y="0" fill={colors[order[0] - 1]} />
      </g>
      <g className={classes[`parallax${order[1]}`]}>
        <use xlinkHref="#gentle-wave" x="50" y="1" fill={colors[order[1] - 1]} />
      </g>
      <g className={classes[`parallax${order[2]}`]}>
        <use xlinkHref="#gentle-wave" x="50" y="2" fill={colors[order[2] - 1]} />
      </g>
      <g className={classes[`parallax${order[3]}`]}>
        <use xlinkHref="#gentle-wave" x="50" y="7" fill={colors[order[3] - 1]} />
      </g>
    </svg>
  );
};
