import { LinkItem } from '../../components/PageHeader';

export const PageLinks: LinkItem[] = [
  { link: '#about', label: 'Om Grantme', newTab: false },
  { link: '#process', label: 'Process', newTab: false },
  { link: '#functions', label: 'Funktioner', newTab: false },
  { link: '#team', label: 'Om oss', newTab: false },
  { link: '#contact', label: 'Kontakt', newTab: false },
  { link: 'https://admin.grantme.se', label: 'Logga in', newTab: true },
];
