import { Overlay, Container, Title, rem } from '@mantine/core';
import React from 'react';
import { Waves } from '../../components/Waves';
import { createStyles } from '@mantine/emotion';

const useStyles = createStyles((theme, _, u) => ({
  hero: {
    maxHeight: '800px',
    width: '100%',
    top: 0,
    position: 'relative',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    transition: 'max-height 1s ease-in-out',
    paddingBottom: '64px',
  },

  scrolled: {
    maxHeight: '80px',
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingTop: '148px',
    paddingBottom: '96px',
    zIndex: 11,
    position: 'relative',
  },

  title: {
    color: theme.colors.gray[0],
    fontSize: rem(60),
    fontWeight: 700,
    lineHeight: 1.2,

    [u.smallerThan('sm')]: {
      fontSize: rem(36),
      lineHeight: 1.2,
    },

    [u.smallerThan('xs')]: {
      fontSize: rem(28),
      lineHeight: 1.3,
    },
  },

  description: {
    color: theme.white,
    maxWidth: 600,

    [u.smallerThan('sm')]: {
      maxWidth: '100%',
      fontSize: theme.fontSizes.sm,
    },
  },

  control: {
    marginTop: `calc(${theme.spacing.xl} * 1.5)`,

    [u.smallerThan('sm')]: {
      width: '100%',
    },
  },
  wavesContainer: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
    display: 'flex',
  },
}));

interface PageHeroProps {
  waveColors: string[];
  waveHeight: number | string;
}

export const PageHeroPriceList: React.FC<PageHeroProps> = ({ waveColors, waveHeight }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.hero}>
      <Overlay
        gradient={'radial-gradient(circle, hsla(193, 28%, 29%, 1) 0%, hsla(193, 28%, 29%, 1) 0%, hsla(203, 87%, 15%, 1) 63%, hsla(202, 100%, 5%, 1) 100%)'}
        opacity={1}
        zIndex={0}
      />

      <Container className={classes.container} style={{ paddingTop: 72, paddingBottom: 48 }}>
        <Title className={classes.title} style={{ fontSize: '14px', fontWeight: 400 }}>
          Uppdaterad 2024-11-06
        </Title>
      </Container>
      <div className={classes.wavesContainer}>
        <Waves colors={waveColors} order={[1, 2, 3, 4]} height={waveHeight} />
      </div>
    </div>
  );
};
