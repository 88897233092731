import {
  Anchor,
  Card,
  Container,
  Grid,
  Group,
  List,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { createStyles } from '@mantine/emotion';
import { PageHeader } from '../../components/PageHeader';
import { PageHeroPriceList } from '../landing-page/PageHeroPriceList';
import { useMediaQuery } from '@mantine/hooks';

const useStyles = createStyles((theme) => ({
  content: {
    width: '100%',
    height: '100%',
    '> #section:nth-of-type(odd)': {
      backgroundColor: 'red',
    },
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.colors.gray[0],
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
}));

const WAVE_HEIGHT = '98px';

export const PriceList: React.FC = () => {
  const { classes, theme } = useStyles();

  const smallScreen = useMediaQuery('(max-width: 992px)');
  const CONTAINER_PADDING = smallScreen ? '20px' : '50px';

  const waveColors = ['#d6eefc33', '#d6eefc99', '#d6eefccc', theme.colors.gray[0]];

  return (
    <div className={classes.content}>
      <PageHeader links={[]} />
      <PageHeroPriceList
        waveColors={waveColors} //waveBlue2, waveBlue1, theme.colors.gray[5], waveGray1]}
        waveHeight={WAVE_HEIGHT}
      />
      <div
        className={classes.section}
        id={'section-1'}
        style={{ backgroundColor: theme.colors.gray[0] }}>
        <Container pt={CONTAINER_PADDING} pb={CONTAINER_PADDING}>
          <Grid p={'20px'}>
            <Grid.Col span={{ md: 6 }}>
              <Stack gap={16}>
                <Card shadow="xl" withBorder h={220}>
                  <Stack h={'100%'} justify="space-between">
                    <Title size={'h3'}>Basic</Title>
                    <List>
                      <List.Item>
                        <Text size={'sm'} fw={400} color={theme.colors.gray[8]}>
                          Max <Text span color={'blue'} size={'md'} fw={600} fs={'normal'}>25</Text> ansökningar per år
                        </Text>
                      </List.Item>
                      <List.Item>
                        <Text size={'sm'} fw={400} color={theme.colors.gray[8]}>
                          Max <Text span color={'blue'} size={'md'} fw={600} fs={'normal'}>1</Text> pågående ansökningsperiod
                        </Text>
                      </List.Item>
                      <List.Item>
                        <Text size={'sm'} fw={400} color={theme.colors.gray[8]}>
                          Max <Text span color={'blue'} size={'md'} fw={600} fs={'normal'}>3</Text> användare
                        </Text>
                      </List.Item>
                    </List>
                    <Stack p={0} gap={0}>
                      <Text size={'sm'} fw={400} fs={'italic'} color={'gray.9'}>
                        <Text span color={'blue'} size={'xl'} fw={600} fs={'normal'}>
                          690 kr
                        </Text>{' '}
                        per månad exkl. moms
                      </Text>
                      <Text size={'xs'} fw={300} color={'gray.9'}>
                        (faktureras årsvis)
                      </Text>
                    </Stack>
                  </Stack>
                </Card>
                <Card shadow="xl" withBorder h={220}>
                  <Stack h={'100%'} justify="space-between">
                    <Title size={'h3'}>Standard</Title>
                    <List>
                      <List.Item>
                        <Text size={'sm'} fw={400} color={theme.colors.gray[8]}>
                          Max <Text span color={'blue'} size={'md'} fw={600} fs={'normal'}>100</Text> ansökningar per år
                        </Text>
                      </List.Item>
                      <List.Item>
                        <Text size={'sm'} fw={400} color={theme.colors.gray[8]}>
                          Max <Text span color={'blue'} size={'md'} fw={600} fs={'normal'}>3</Text> pågående ansökningsperiod
                        </Text>
                      </List.Item>
                      <List.Item>
                        <Text size={'sm'} fw={400} color={theme.colors.gray[8]}>
                          Max <Text span color={'blue'} size={'md'} fw={600} fs={'normal'}>5</Text> användare
                        </Text>
                      </List.Item>
                    </List>
                    <Stack p={0} gap={0}>
                      <Text size={'sm'} fw={400} fs={'italic'} color={'gray.9'}>
                        <Text span color={'blue'} size={'xl'} fw={600} fs={'normal'}>
                          1 290 kr
                        </Text>{' '}
                        per månad exkl. moms
                      </Text>
                      <Text size={'xs'} fw={300} color={'gray.9'}>
                        (faktureras årsvis)
                      </Text>
                    </Stack>
                  </Stack>
                </Card>
                <Card shadow="xl" withBorder h={220}>
                  <Stack h={'100%'} justify="space-between">
                    <Title size={'h3'}>Pro</Title>
                    <List>
                      <List.Item>
                        <Text size={'sm'} fw={400} color={theme.colors.gray[8]}>
                          Max <Text span color={'blue'} size={'md'} fw={600} fs={'normal'}>300</Text> ansökningar per år
                        </Text>
                      </List.Item>
                      <List.Item>
                        <Text size={'sm'} fw={400} color={theme.colors.gray[8]}>
                          Max <Text span color={'blue'} size={'md'} fw={600} fs={'normal'}>6</Text> pågående ansökningsperiod
                        </Text>
                      </List.Item>
                      <List.Item>
                        <Text size={'sm'} fw={400} color={theme.colors.gray[8]}>
                          Max <Text span color={'blue'} size={'md'} fw={600} fs={'normal'}>10</Text> användare
                        </Text>
                      </List.Item>
                    </List>
                    <Stack p={0} gap={0}>
                      <Text size={'sm'} fw={400} fs={'italic'} color={'gray.9'}>
                        <Text span color={'blue'} size={'xl'} fw={600} fs={'normal'}>
                          2 990 kr
                        </Text>{' '}
                        per månad exkl. moms
                      </Text>
                      <Text size={'xs'} fw={300} color={'gray.9'}>
                        (faktureras årsvis)
                      </Text>
                    </Stack>
                  </Stack>
                </Card>
              </Stack>
            </Grid.Col>
            <Grid.Col span={{ md: 6 }}>
              <Stack gap={16}>
                <Card shadow="xl" withBorder h={200}>
                  <Stack gap={8} justify="space-between" h={'100%'}>
                    <Title size={'h3'}>Anpassad</Title>
                    <Text size={'sm'} fw={400} color={theme.colors.gray[8]}>
                      För organisationer med krav på att kunna hantera en stor mängd ansökningar,
                      stiftelser, perioder och/eller användare
                    </Text>
                    <Text size={'sm'} fw={600}>
                      Maila till {<Anchor>info@grantme.se</Anchor>} för offert
                    </Text>
                  </Stack>
                </Card>

                <Card shadow="xl" withBorder>
                  <Stack gap={0}>
                    <Title size={'h3'} pb={8}>
                      Tillval
                    </Title>
                    <Group style={{ justifyContent: 'space-between' }}>
                      <Text size={'xs'} fw={400}>
                        Uppstartspaket
                      </Text>
                      <Text size={'xs'} fw={400}>
                        20 000 kr exkl. moms
                      </Text>
                    </Group>
                    <Group style={{ justifyContent: 'space-between' }}>
                      <Text size={'xs'} fw={400}>
                        Löpande support<br/>
                      </Text>
                      <Text size={'xs'} fw={400}>
                        900 kr/h exkl. moms
                      </Text>
                    </Group>
                    <Group style={{ justifyContent: 'space-between' }}>
                      <Text size={'xs'} fw={400}>
                      (Minsta debiterbar tid är 30 min)
                      </Text>
                    </Group>
                    
                  </Stack>
                </Card>
              </Stack>
            </Grid.Col>
          </Grid>
        </Container>
      </div>
      <div
        className={classes.section}
        id={'section-2'}
        style={{ backgroundColor: theme.colors.gray[0] }}>
        <Container pt={CONTAINER_PADDING} pb={CONTAINER_PADDING}></Container>
      </div>
    </div>
  );
};
