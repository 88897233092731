import { Overlay, Container, Title, Button, Text, rem } from '@mantine/core';
import React from 'react';
import { Waves } from './Waves';
import { createStyles } from '@mantine/emotion';

const useStyles = createStyles((theme, p, u) => ({
  hero: {
    maxHeight: '800px',
    width: '100%',
    top: 0,
    position: 'relative',
    //backgroundImage: 'url(/images/heroImage.jpg)',
    // 'url(https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    transition: 'max-height 1s ease-in-out',
    paddingBottom: '64px',
  },

  scrolled: {
    maxHeight: '80px',
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    // paddingBottom: `calc(${theme.spacing.xl} * 6)`,
    paddingTop: '148px',
    paddingBottom: '96px',
    zIndex: 11,
    position: 'relative',
  },

  isScrolledContainer: {
    // display: 'none',
  },

  title: {
    fontSize: rem(60),
    fontWeight: 700,
    lineHeight: 1.2,

    [u.smallerThan('sm')]: {
      fontSize: rem(36),
      lineHeight: 1.2,
    },

    [u.smallerThan('xs')]: {
      fontSize: rem(28),
      lineHeight: 1.3,
    },
  },

  description: {
    color: theme.white,
    maxWidth: 600,

    [u.smallerThan('sm')]: {
      maxWidth: '100%',
      fontSize: theme.fontSizes.sm,
    },
  },

  control: {
    marginTop: `calc(${theme.spacing.xl} * 1.5)`,

    [u.smallerThan('sm')]: {
      width: '100%',
    },
  },
  wavesContainer: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
    display: 'flex',
  },
}));

interface PageHeroProps {
  waveColors: string[];
  waveHeight: number | string;
}

export const PageHero: React.FC<PageHeroProps> = ({ waveColors, waveHeight }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.hero}>
      <Overlay
        gradient={'radial-gradient(circle, hsla(193, 28%, 29%, 1) 0%, hsla(193, 28%, 29%, 1) 0%, hsla(203, 87%, 15%, 1) 63%, hsla(202, 100%, 5%, 1) 100%)'}
        opacity={1}
        zIndex={0}
      />

      <Container className={classes.container}>
        <Title className={classes.title} c={'white'}>
          Marknadens mest flexibla och kraftfulla ansökningssystem för stiftelser och föreningar
        </Title>
      </Container>
      <div className={classes.wavesContainer}>
        <Waves colors={waveColors} order={[1, 2, 3, 4]} height={waveHeight} />
      </div>
    </div>
  );
};
