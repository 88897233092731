import {
  Container,
  Grid,
  Text,
  Stack,
  Center,
  Image,
  Anchor,
  Title,
  Flex,
  Box,
  useMantineTheme,
} from '@mantine/core';
import React, { useRef } from 'react';
import { PageHeader } from '../../components/PageHeader';
import { PageHero } from '../../components/PageHero';
import { Waves } from '../../components/Waves';
import { CardGrid, InformationItem } from '../../components/CardGrid';
import {
  IconAccessible,
  IconBarbell,
  IconBrain,
  IconCalendar,
  IconCash,
  IconChecklist,
  IconClockPlay,
  IconFileSpreadsheet,
  IconGavel,
  IconMailbox,
  IconWhirl,
  IconWorldWww,
} from '@tabler/icons';
import { PageFooter } from '../../components/PageFooter';
import { CardGrid2 } from '../../components/CardGrid2';
import { useMediaQuery } from '@mantine/hooks';
import { PageLinks } from './PageLinks';
import { Carousel } from '@mantine/carousel';
import Autoplay from 'embla-carousel-autoplay';
import classes from './StartViewNew.module.css';

const flowData: InformationItem[] = [
  {
    Icon: IconCalendar,
    Title: 'Ansökningsperiod',
    Text: 'Skapa ett ansökningsformulär eller använd ett tidigare skapat.',
  },
  {
    Icon: IconChecklist,
    Title: 'Granska',
    Text: 'Betygsätt, kommentera, föreslå belopp och eventuellt be sökande komplettera ansökningar.',
  },
  {
    Icon: IconGavel,
    Title: 'Besluta',
    Text: 'Fatta beslut utifrån granskarnas betyg och förslag.',
  },
  {
    Icon: IconMailbox,
    Title: 'Meddela beslut',
    Text: 'Systemet skickar ut meddelande till sökande enligt era önskemål.',
  },
  {
    Icon: IconCash,
    Title: 'Medel utbetalas',
    Text: 'Betala ut anslag, direkt eller be sökande rekvirera. Systemet genererar en betalfil enligth ISO 20022 standard.',
  },
  {
    Icon: IconFileSpreadsheet,
    Title: 'Återrapportering',
    Text: 'Systemet skapar en återrapporteringsperiod för sökande.',
  },
];

const appInformation: InformationItem[] = [
  {
    Icon: IconBarbell,
    Image: 'images/lion.jpg',
    Title: 'Kraftfullt',
    Text: 'Grantme tar hand om hela ansökningsprocessen. Förutom att granska ansökningar så kan ni exempelvis skicka meddelande till sökande, skapa betalfiler, skicka ut digitala rekvisitioner och hantera återrapporteringar.',
  },
  {
    Icon: IconAccessible,
    Image: 'images/simple.jpg',
    Title: 'Användarvänligt',
    Text: 'Många stiftelser granskar ansökningar en gång per år vilket gör att det går lång tid mellan granskningarna. Ett system som inte är intuitivt gör att det tar lång tid för granskarna att påbörja sitt arbete. Grantme är skapat för att det skall vara lätt att komma igång och enkelt för användarna.',
  },
  {
    Icon: IconWhirl,
    Image: 'images/flexible.jpg',
    Title: 'Flexibelt',
    Text: 'Grantme anpassas till er verksamhet istället för tvärtom. Ni kan skapa och ändra ansökningsformulär direkt i systemet. Det är möjligt att ha flera olika ansökningsperioder samtidigt. Vid granskning så bestämmer ni själva vilka bedömningskriterier ni önskar.',
  },
  {
    Icon: IconBrain,
    Image: 'images/owl.jpg',
    Title: 'Smart',
    Text: 'Vårat egna G-score tar hänsyn till hur granskarna betygsätter ansökningar. I beslutsvyn kommer ni se ett betyg som vi kallar G-score som har tagit hänsyn till hur granskarna historisk betygsatt ansökningar.',
  },
  {
    Icon: IconClockPlay,
    Image: 'images/future.jpg',
    Title: 'Framtidssäkrat',
    Text: 'Dataskyddsförordningen ställer krav på hur stiftelsen hanterar personuppgifter. Grantme är anpassat för att ni skall kunna hantera ansökningar på ett korrekt sätt, när ni öppnar en bilaga i ansökan så laddas den inte ner på er datorn som så ofta sker. Betalfilerna som skapas är anpassat till den nya internationella standarden ISO20022.',
  },
  {
    Icon: IconClockPlay,
    Image: 'images/env.jpg',
    Title: 'Hållbarhet',
    Text: 'För många stiftelser är det viktigt att ta ansvar gällande miljö och hållbarhet. Massa- och pappersindustrin är en verksamhet som i regel medför betydande utsläpp av miljöfarliga ämnen till både vatten och luft. I tillverkningen används även stora mängder energi. Genom att digitalisera er ansökningsprocess så minskar användningen av papper och även av posttransporter vilket leder till att ni minskar ert klimatavtryck.',
  },
  {
    Icon: IconWorldWww,
    Image: 'images/house.jpg',
    Title: 'Startsida',
    Text: 'Saknar ni hemsida idag så är det fullt möjligt att ändå använda Grantme. Ni får en egen startsida där ni kan skriva om stiftelsen och ge information till sökande. Startsidan skapar och ändrar ni direkt i systemet.',
  },
];

const WAVE_HEIGHT = '98px';

const footerLinks = {
  data: [
    {
      title: 'Grantme',
      links: [
        {
          label: 'Om Grantme',
          link: '#about',
        },
        {
          label: 'Process',
          link: '#process',
        },
        {
          label: 'Funktioner',
          link: '#functions',
        },
        {
          label: 'Om oss',
          link: '#team',
        },
        {
          label: 'Kontakt',
          link: '#contact',
        },
      ],
    },
  ],
};

export const StartViewNew: React.FC = () => {
  const autoPlay = useRef(Autoplay({ delay: 4000 }));
  const theme = useMantineTheme();

  const smallScreen = useMediaQuery('(max-width: 992px)');
  const CONTAINER_PADDING = smallScreen ? '20px' : '50px';

  const waveColors = ['#d6eefc33', '#d6eefc99', '#d6eefccc', theme.colors.gray[0]];

  return (
    <div className={classes.content}>
      <PageHeader links={PageLinks} />
      <PageHero waveColors={waveColors} waveHeight={WAVE_HEIGHT} />

      <div
        className={classes.section}
        id={'about'}
        style={{ backgroundColor: theme.colors.gray[0] }}>
        <Container pt={CONTAINER_PADDING} pb={CONTAINER_PADDING} maw={'60rem'}>
          <Grid p={'20px'}>
            <Grid.Col span={{ md: 6 }}>
              <Stack gap={32} pb={32}>
                <Text size={'md'} fw={400} c={theme.colors.gray[8]}>
                  Grantme är utvecklat tillsammans med personer med lång erfarenhet inom stiftelser
                  och ideella organisationer.
                </Text>
                <Text size={'md'} fw={400} c={theme.colors.gray[8]}>
                  Tjänsten hjälper er att effektivisera er ansökningsprocess, uppfylla lagkrav som
                  GDPR och spara tid genom att automatisera manuella processer.
                </Text>
                <Text size={'md'} fw={400} c={theme.colors.gray[8]}>
                  Skapa ansökningsformulär precis som ni vill ha dem i vår formulärbyggare, hantera
                  kommunikation med sökande, granska ansökningar, fatta beslut och skapa betalfiler
                  för utbetalning.
                </Text>
                <Text size={'md'} fw={400} c={theme.colors.gray[8]}>
                  Grantme meddelar sökande beslut och begär in återrapporteringar från de som blivit
                  beviljade medel.
                </Text>
              </Stack>
            </Grid.Col>
            <Grid.Col
              span={{ md: 6 }}
              style={{ display: 'flex', justifyContent: 'center', padding: '0 24px' }}>
              <Center>
                <Image maw={500} src="./screen1.png" />
              </Center>
            </Grid.Col>
          </Grid>
        </Container>
        <Waves
          colors={['#d6eefc33', '#d6eefc99', '#d6eefccc', '#052E47ff']}
          order={[1, 2, 3, 4]}
          height={'32px'}
        />
      </div>

      <div className={classes.section} id={'process'} style={{ backgroundColor: '#052E47' }}>
        <Container m={0} maw={800} size={'md'} pt={CONTAINER_PADDING} pb={CONTAINER_PADDING}>
          <CardGrid
            Items={flowData}
            HasBox={false}
            TextColor={theme.colors.gray[1]}
            IconColor={theme.colors.gray[1]}
          />
        </Container>
        <Waves colors={waveColors} order={[1, 2, 3, 4]} height={'32px'} />
      </div>

      <div
        className={classes.section}
        id={'functions'}
        style={{ backgroundColor: theme.colors.gray[0] }}>
        <Container m={0} maw={800} size={'md'} pt={CONTAINER_PADDING} pb={CONTAINER_PADDING}>
          <CardGrid2 Items={appInformation} />
        </Container>
        <Waves
          colors={['#d6eefc33', '#d6eefc99', '#d6eefccc', '#052E47ff']}
          order={[1, 2, 3, 4]}
          height={'32px'}
        />
      </div>

      <div className={classes.section} id={'references'} style={{ backgroundColor: '#052E47' }}>
        <Container w={'100%'} pt={CONTAINER_PADDING} pb={CONTAINER_PADDING}>
          <Carousel
            withIndicators
            withControls={false}
            height={250}
            plugins={[autoPlay.current]}
            onMouseEnter={autoPlay.current.stop}
            onMouseLeave={autoPlay.current.reset}
            loop>
            <Carousel.Slide className={classes.carouselSlide}>
              <div className={classes.imageContainer}>
                <Image
                  fit={'contain'}
                  w={'auto'}
                  radius={12}
                  src={'logos/Svenska_Tecknare_o.gif'}
                  mah={140}
                />
                <Flex direction={'column'} gap={0} pl={26} c={'red'} color="green">
                  <Text span fw={200} fz={70} c={'white'} lh={'50px'}>
                    Svenska
                  </Text>
                  <Text span fw={900} fz={56} c={'white'} lh={'50px'}>
                    Tecknare
                  </Text>
                </Flex>
              </div>
            </Carousel.Slide>
            <Carousel.Slide className={classes.carouselSlide}>
              <div className={classes.imageContainer}>
                <Flex direction={'column'} gap={0} pl={26} c={'white'} color="green">
                  <Text span style={{ whiteSpace: 'nowrap' }} fw={700} fz={42} c={'white'} lh={'36px'}>
                    Stiftelsen Bromströmska
                  </Text>
                  <Text span style={{ whiteSpace: 'nowrap' }} fw={600} lts={8.3} fz={26} c={'white'} lh={'36x'}>
                    Sjukhemmets Donationer
                  </Text>
                </Flex>
              </div>
            </Carousel.Slide>
            <Carousel.Slide className={classes.carouselSlide}>
              <div className={classes.imageContainer}>
                <Image w={'auto'} radius={12} src={'logos/logoSlojdf.png'} mah={150} />

                <Box pl={26} c={'red'} color="green">
                  <Title order={1} fz={72} c={'white'}>
                    Göteborgs
                  </Title>
                  <Title order={2} fz={56} c={'white'}>
                    Slöljdförening
                  </Title>
                </Box>
              </div>
            </Carousel.Slide>
            <Carousel.Slide className={classes.carouselSlide}>
              <div className={classes.imageContainer}>
                <Flex direction={'column'} gap={0} pl={26} c={'white'} color="green">
                  <Text span fw={400} fz={52} c={'white'} lh={'40px'}>
                    Forskningsstiftelsen
                  </Text>
                  <Text span fw={600} fz={15} c={'white'} lh={'40px'}>
                    vid Kardiologiska sektionen vid Centralkliniken å Östra sjukhuset i
                  </Text>

                  <Text span fw={900} fz={80} c={'white'} lh={'52px'} lts={12}>
                    Göteborg
                  </Text>
                </Flex>
              </div>
            </Carousel.Slide>
          </Carousel>
        </Container>
        <Waves colors={waveColors} order={[1, 3, 2, 4]} height={'32px'} />
      </div>

      <div
        className={classes.section}
        id={'team'}
        style={{ backgroundColor: theme.colors.gray[0] }}>
        <Container size={'md'} pt={CONTAINER_PADDING} pb={CONTAINER_PADDING}>
          <Stack>
            <Text>
              Vårt fokus är att göra ansökningsprocessen enklare och mer värdeskapande för
              stiftelser och organisationer. Möjligheterna är oändliga och vår resa har bara börjat.
              Genom ett nära samarbete med Söderberg & Partners och våra kunder, formar vi en tjänst
              av världsklass.
            </Text>
            <Text>
              Vi är ett samspelt team bestående av sex skickliga medarbetare som tillsammans har
              nästan ett sekel erfarenhet inom mjukvaruutveckling. Från vårt kontor i Växjö arbetar
              vi passionerat för att skapa en bättre morgondag, lösa komplexa problem och erbjuda
              smidiga lösningar.
            </Text>
            <Center>
              <Image maw={600} src="team.jpg" />
            </Center>
            <Center>
              <Text c={theme.colors.gray[6]} fs={'italic'}>
                Teamet bakom Grantme: Johan, Jonatan, Erik, Fredrik, Tim och Tobias
              </Text>
            </Center>
          </Stack>
        </Container>
        <Waves
          colors={['#d6eefc33', '#d6eefc99', '#d6eefccc', '#052E47ff']}
          order={[1, 2, 3, 4]}
          height={'32px'}
        />
      </div>

      <div className={classes.section} id={'contact'} style={{ backgroundColor: '#052E47' }}>
        <Container pt={CONTAINER_PADDING} pb={CONTAINER_PADDING}>
          <Stack>
            {/* <EmailForm /> */}
            <Text c={theme.colors.gray[0]} size={'lg'} ta="center">
              Kontakta oss på{' '}
              <Anchor c={theme.colors.gray[0]} fw={'bold'} href="mailto:info@grantme.se">
                info@grantme.se
              </Anchor>{' '}
              för frågor eller om ni önskar en utförligare presentation.
            </Text>
            <Center>
              <img width="290" src="spws.png" />
            </Center>
            <Text c={theme.colors.gray[0]} size={'lg'} ta="center">
              I samarbete med Söderberg & Partners Wealth Services AB
            </Text>
          </Stack>
        </Container>
        <Waves colors={waveColors} order={[1, 2, 3, 4]} height={'32px'} />
      </div>

      <div className={classes.section} style={{ backgroundColor: theme.colors.gray[0] }}></div>
      <PageFooter data={footerLinks.data} />
    </div>
  );
};
