import { Text, Grid, Stack, Paper } from '@mantine/core';
import { createStyles } from '@mantine/emotion';
import { TablerIcon } from '@tabler/icons';

import React from 'react';

const iconHeight = 72;

const useStyles = createStyles(() => ({
  iconContainer: {
    minHeight: `${iconHeight}px`,
  },
  paperWrapper: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  paper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export interface InformationItem {
  Title: string;
  Text: string;
  Icon: TablerIcon;
  Image?: string;
}

interface CardGridProps {
  Items: InformationItem[];
  HasBox: boolean;
  IconColor?: string;
  TextColor?: string;
}

export const CardGrid: React.FC<CardGridProps> = ({ Items, HasBox, IconColor, TextColor }) => {
  const { classes, theme } = useStyles();

  const defaultIconColor = IconColor ?? theme.colors.blue[7];
  const defaultTextColor = TextColor ?? theme.black;

  const getTexts = (item: InformationItem) => {
    return (
      <>
        <Text fw={700} pt={12} c={defaultTextColor}>
          {item.Title}
        </Text>
        <Text size={'sm'} ta={'center'} c={defaultTextColor}>
          {item.Text}
        </Text>
      </>
    );
  };

  const handleText = (item: InformationItem) => {
    if (HasBox) {
      return (
        <div className={classes.paperWrapper}>
          <Paper
            display={'flex'}
            bg={defaultIconColor}
            className={classes.paper}
            pb={12}
            mt={8}
            radius={0}
            pr={16}
            pl={16}>
            {getTexts(item)}
          </Paper>
        </div>
      );
    }

    return getTexts(item);
  };

  return (
    <Grid overflow={'hidden'} gutter={HasBox ? 60 : 100} pt={40} pb={40} m={0} w={'100%'}>
      {Items.map((item, index) => {
        return (
          <Grid.Col key={item.Title + index} span={{ md: 4,  sm: 6 }}>
            <Stack align={'center'} h={'100%'} gap={0}>
              <div style={{ minHeight: '96px' }}>
                <item.Icon stroke={0.8} size={iconHeight} color={defaultIconColor} />
              </div>
              {handleText(item)}
            </Stack>
          </Grid.Col>
        );
      })}
    </Grid>
  );
};
